.ProgramItemRow {
  display: grid;
  padding: 2px 0;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid var(--main-bg-color);
  width: fit-content;
  min-width: 100%;

  &:last-child {
    border-bottom: none;
  }

  .programItemColumn {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 14px;
    font-weight: bold;

    &.dragIndication {
      cursor: move;

      svg {
        path {
          fill: var(--indication-color);
        }
      }
    }

    &.programNameAndDescription {
      justify-content: start;
      flex-direction: column;
      align-items: start;

      .routineName {
        color: var(--info-color);
      }

      .routineDescription {
        color: var(--primary-color);
        font-weight: normal;
      }
    }

    &.options {
      justify-content: center;

      .optionsBtn {
        cursor: pointer;
      }
    }
  }
}

.listGrid {
  grid-template-columns: minmax(34px, 0.5fr) minmax(300px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(70px, 1fr);
}
