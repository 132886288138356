.rangeInputFieldWrapper {
  width: 100%;
  position: relative;

  .rangeInputField {
    color: var(--primary-color);

    // If you want the outline color to match your theme, you can override MUI styles:
    :global(.MuiOutlinedInput-root) {
      fieldset {
        border-color: var(--indication-color);
      }
    }
  }
}