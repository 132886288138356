.trainerDataDialog {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.submit {
  min-width: 140px;

  .submitText {
    margin-left: 6px;
  }
}
