.addWorkoutDialog {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 0;
  height: 250px;

  .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--Dashboard-Brand-bluegray, #262D3C) !important;
    border-radius: 12px !important;
    width: 40%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08) !important;
    }

    .optionText {
      margin: 0 8px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}


.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 1px solid var(--info-color) !important;
  border-radius: 12px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  .submitText {
    margin: 0 8px;
    color: var(--info-color);
    font-size: 12px;
    font-weight: bold;
  }
}