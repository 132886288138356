.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  height: 85px;
  padding: 8px;
  border: 1px solid var(--indication-color);
  border-radius: 15px;
}

.repNumber {
  font-size: 12px;
  color: var(--primary-color);
  margin-bottom: 4px;
}

.boxes {
  display: flex;
  gap: 8px;
}

.box {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 5px;
  background-color: green;
  transition: background-color 0.2s;
  /* default for good */

  &.Good {
    background-color: green;
  }

  &.Fast {
    background-color: yellow;
  }

  &.Slow {
    background-color: red;
  }

  &.Empty {
    background-color: var(--empty-slot-color);
  }

  .label {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    width: 100%;
    text-align: center;
  }
}