$group-set-grid-columns: minmax(60px, 0.5fr) // "SET #"
  minmax(80px, 1fr) // Weight
  minmax(80px, 1fr) // Reps
  minmax(80px, 1fr) // Rest
  minmax(80px, 1fr) // Effort
  minmax(60px, 0.5fr); // Delete icon

$automatic-group-set-grid-columns: minmax(60px, 0.5fr) // "SET #"
  minmax(50px, 1fr) // Weight
  minmax(50px, 1fr) // Reps
  minmax(50px, 1fr) // Rest
  minmax(70px, 1fr) // Effort
  minmax(70px, 1fr) // Concentric range
  minmax(70px, 1fr) // Eccentric range
  minmax(60px, 0.5fr); // Delete icon




.setProperties {
  display: grid;
  grid-template-columns: $group-set-grid-columns;
  min-width: 600px; // Ensure this exceeds the view width
}

.automaticSetProperties {
  display: grid;
  grid-template-columns: $automatic-group-set-grid-columns;
  min-width: 600px; // Ensure this exceeds the view width
}

.setValues {
  background-color: var(--main-bg-color);
  width: 100%;
  margin: 6px 0;
  border-radius: 6px;

  .setTitle,
  .deleteSet {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--info-color);
    font-size: 16px;

    svg {
      cursor: pointer;
      width: 40px;
    }
  }

  .setInput {
    max-width: 60px;
    justify-self: center;
    align-self: center;
    margin: 6px 0;

    input {
      height: 30px;
      padding: 0;
      text-align: center;
    }
  }

  .metricInput {
    max-width: 80px;
    justify-self: center;
    align-self: center;
    margin: 6px 0;

    input {
      height: 30px;
      padding: 0;
      text-align: center;
    }
  }

  .dropDownInput {
    max-width: 80px;
    justify-self: center;
    align-self: center;
    margin: 6px 0;

    input {
      height: 30px;
      padding: 0;
      text-align: center;
    }
  }
}