.menu {
  :global(.MuiPaper-root) {
    min-width: 200px;
    border-radius: 15px;
    background-color: var(--main-bg-color);

    :global(.MuiList-root) {
      padding: 0;
    }
  }

  .menuItem {
    padding: 12px;
    border-bottom: 1px solid var(--indication-color);

    &:last-child {
      border-bottom: none;
    }

    .optionsMenuText {
      font-weight: bold;
      margin-left: 10px;
      color: var(--primary-color);
    }
  }
}