@import '../../constants/styleConsts';

.searchBar {
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 400px;

  input {
    height: 45px;
    flex: 1;
    padding: 0.5rem;
    border: 1px solid var(--indication-color);
    border-radius: 10px;
    color: var(--indication-color);
    background-color: transparent;
    max-width: 100%;

    @media (max-width: $small-screen-width) {
      font-size: 12px;
    }

    @media (max-width: 400px) {
      font-size: 10px;
    }
  }

  button {
    padding: 0.5rem 1rem;
    background-color: #1976d2;
    color: var(--primary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #1565c0;
    }
  }
}