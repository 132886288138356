@import '../../constants/styleConsts';

.dashboardContentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
  padding: 0 24px;
  background-color: var(--secondary-bg-color);
  border-radius: 20px;
  position: relative;

  &.isContentWrapped {
    height: calc(100% - 24px);
  }

  @media (max-width: $medium-screen-width) {
    padding: 0 12px;
  }

  @media (max-width: $small-screen-width) {
    padding: 0 8px;
    margin-top: 0;
  }

  .dashboardContentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashboardTitleWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .title {
        color: var(--primary-color);
        font-weight: bold;
        font-size: 24px;

        @media (max-width: $medium-screen-width) {
          font-size: 18px;
        }
      }
    }

    .backButton {
      background: transparent;
      border: none;
      cursor: pointer;
      max-width: 40px !important;

      &:hover {
        color: #ccc;
      }
    }
  }

  .content {
    position: relative;
    margin-top: 1rem;
    flex-grow: 1;

    .listActionsWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      .actionItems {
        margin-left: 8px;

        .addClientBtn {
          background-color: var(--info-color);
          height: 35px;
          border-radius: 12px;
          margin-left: 12px;

          svg {
            path {
              fill: var(--primary-color);
            }
          }

          .addClientBtnText {
            font-weight: bold;
            margin-left: 10px;
            margin-right: 4px;
            text-transform: none;
            text-wrap: nowrap;
          }
        }
      }
    }

    .mainContent {
      transition: transform 0.3s ease;

      &.drilledInMainContent {
        transform: translateX(-100%);
        visibility: hidden;
      }
    }

    .drillInContent {
      height: 100%;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.3s ease;
      transform: translateX(100%);
      width: 100%;

      &.isDrilled {
        visibility: visible;
        transform: translateX(0);
      }
    }
  }
}