.list {
  overflow-x: auto;
  background-color: var(--modal-bg-color);
  border-radius: 20px;

  .listHeader {
    text-align: center;
    padding: 2px 18px;
    display: grid;
    height: 40px;
    align-items: center;
    font-weight: bold;

    .tableTitle {
      font-size: 14px;
      color: #8c8f95;
      text-transform: uppercase;

      &.leftTableTitle {
        text-align: left;
      }
    }
  }
}
