.selectMembersDialog {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  height: 250px;

  .search {
    max-width: none;
    margin-bottom: 12px;
    flex-grow: 0;
  }

  .list {
    flex-grow: 1;
    margin: 18px 0;

    .memberMultiSelectItem {
      display: grid;
      grid-template-columns: minmax(300px, 1fr) minmax(140px, 1fr) minmax(160px, 1fr);
      color: var(--primary-color);
      font-weight: bold;
      font-size: 14px;

      .memberNameWrapper {
        display: flex;
        align-items: center;
        justify-content: start;

        .memberName {
          margin-left: 6px;
          color: var(--info-color);
          text-decoration: underline;
        }
      }
    }
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 1px solid var(--info-color) !important;
  border-radius: 12px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  .submitText {
    margin: 0 8px;
    color: var(--info-color);
    font-size: 12px;
    font-weight: bold;
  }
}