.list {
  overflow-x: auto;
  background-color: var(--modal-bg-color);
  border-radius: 20px;

  .itemWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      flex-grow: 1;
      padding: 0 12px;
    }
  }
}
