.verificationCodeInput {
  $verificationCodeInputWidth: 50px;
  width: $verificationCodeInputWidth;

  @media (max-width: 768px) {
    $verificationCodeInputWidth: 40px;
    width: $verificationCodeInputWidth;
  }

  @media (max-width: 400px) {
    $verificationCodeInputWidth: 30px;
    width: $verificationCodeInputWidth;

    input {
      padding: 8px 6px;
    }
  }
}
