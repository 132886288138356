.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  .iconContainer {
    svg {
      width: 80px;
      height: 80px;

      path {
        fill: #42464e;
      }
    }
  }

  .emptyStateText {
    color: var(--primary-color);
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .emptyStateDescription {
    color: #42464e;
    font-size: 18px;
    margin-bottom: 16px;
  }

  .action {
    .actionText {
      text-transform: none;
      font-weight: bold;
      margin-left: 6px;
    }
  }
}
