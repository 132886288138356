@import '../../constants/styleConsts';

.editProgramWrapper {
  height: calc(100% - 24px);

  .rightContentHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;

    .rightContentHeaderRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 12px;

      .programName {
        justify-self: stretch;
        display: flex;
        flex-direction: column;
        width: 50%;

        @media (max-width: $medium-screen-width) {
          padding: 12px;
        }

        .programNameHeader {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .programNameTitle {
            color: var(--indication-color);
            font-size: 15px;
            font-weight: medium;
          }

          .programNameEdit {
            cursor: pointer;
            margin-left: 12px;
          }
        }

        .programNameContent {
          font-size: 13px;
          color: var(--primary-color);

          .nameTextField {
            input {
              border: none;
              padding: 2px 0;
              font-size: 18px;
              font-weight: bold;
              color: var(--info-color);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 95%;
            }

            &.editedName {
              margin-top: -1px;
              margin-left: -3px;

              input {
                border: 1px solid var(--indication-color);
                border-radius: 5px;
                padding: 2px;
                padding-left: 5px;
              }
            }

            fieldset {
              opacity: 0;
            }
          }

          .nameError {
            input {
              border: none;
              padding: 2px 0;
              font-size: 18px;
              font-weight: bold;
              color: var(--error-color);
            }

            &.editedName {
              margin-top: -1px;
              margin-left: -3px;

              input {
                border: 1px solid var(--error-color);
                border-radius: 5px;
                padding: 2px;
              }
            }

            fieldset {
              opacity: 0;
            }
          }
        }
      }

      .rightContentTitle {
        color: var(--info-color);
        font-size: 22px;
        font-weight: bold;

        @media (max-width: $medium-screen-width) {
          font-size: 18px;
        }
      }

      .weekNumber {
        color: var(--primary-color);
        font-size: 16px;
        font-weight: bold;
      }

      .rightContentWorkoutsTitle {
        color: var(--primary-color);
        font-size: 18px;
        font-weight: bold;
      }

      .addWorkout {
        border-radius: 10px;

        svg {
          height: 20px;
        }

        .addWorkoutText {
          margin-left: 6px;
          color: var(--primary-color);
          font-size: 11px;
          font-weight: bold;
        }
      }
    }

    .toggleViewButtons {
      display: flex;
      justify-content: flex-end;
      margin-left: 12px;

      .toggleViewButton {
        border-radius: 10px;
        background-color: transparent;
      }

      .selectedView {
        background-color: var(--info-color);
      }
    }
  }



  .editProgram {
    display: flex;
    height: calc(100% - 24px); // need padding from bottom + border radius (can't margin bottom)
    overflow: hidden;

    @media (max-width: $small-screen-width) {
      flex-direction: column;
    }

    .leftContent {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
      padding-top: 10px;
      margin-right: 10px;

      @media (max-width: $medium-screen-width) {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
      }

      @media (max-width: $small-screen-width) {
        width: 100%;
        min-width: auto;
        max-width: none;
      }

      .title {
        color: var(--primary-color);
        font-size: 16px;
        margin-bottom: 18px;

        @media (max-width: $medium-screen-width) {
          font-size: 14px;
          text-align: center;
        }

        @media (max-width: $small-screen-width) {
          font-size: 12px;
          text-align: center;
        }
      }

      .weekList {
        border: 1px solid var(--info-color);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 6px 4px;

        .weekListTitle {
          color: var(--primary-color);
          font-size: 16px;
          font-weight: bold;
          margin: 6px 0 8px;
        }

        .week {
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 12px;
          color: var(--primary-color);
          font-weight: bold;
          cursor: pointer;
          padding: 8px 4px;

          &.selectedWeek {
            background-color: var(--info-color);
          }

          .weekText {
            margin-left: 10px;
            flex-grow: 1;
          }

          .weekOptions {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .addWeek {
          background-color: transparent;

          .addWeekText {
            color: var(--info-color);
            text-transform: none;
            font-weight: bold;
            margin-left: 6px;
          }
        }
      }
    }

    .rightContent {
      border-radius: 20px;
      background-color: var(--modal-bg-color);
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 18px;
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      padding-right: 12px;

      @media (max-width: $medium-screen-width) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media (max-width: $small-screen-width) {
        padding-left: 6px;
        padding-right: 6px;
      }

      .rightContentHeaderRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 12px 12px;

        .rightContentWorkoutsTitle {
          color: var(--primary-color);
          font-size: 18px;
          font-weight: bold;
        }
      }


      .programContent {
        display: flex;
        border-radius: 15px;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: auto;

        .weekDescription {}
      }

      .rightContentFooter {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 12px 0;

        .action {
          border-radius: 10px;
          background-color: transparent;

          span {
            font-weight: bold;
            font-size: 12px;
          }
        }

        .submit {
          border: 1px solid var(--info-color);
          margin-right: 12px;

          .submitText {
            color: var(--info-color);
          }
        }

        .cancel {
          border: 1px solid var(--primary-color);

          .cancelText {
            color: var(--primary-color);
          }
        }
      }
    }

    .calendarView {
      border-radius: 20px;
      // background-color: var(--modal-bg-color);
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 18px;
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      padding-right: 12px;

      @media (max-width: $medium-screen-width) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media (max-width: $small-screen-width) {
        padding-left: 6px;
        padding-right: 6px;
      }

      .programContent {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: auto;

        .calendarWeeks {

          .calendarMonthHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            .calendarMonthTitle {
              color: var(--primary-color);
              font-size: 18px;
              font-weight: bold;
            }

            .calendarMonthOptions {
              display: flex;
              align-items: center;
            }
          }

          .calendarWeekWrapper {
            height: calc(100vh - 500px);
            // width: calc(100vw - 400px);
            margin-bottom: 20px;



            .calendarWeekHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 12px;

              .calendarWeekTitle {
                color: var(--primary-color);
                font-size: 18px;
                font-weight: bold;
              }

              .calendarWeekOptions {
                display: flex;
                align-items: center;
              }
            }

            .weekContainer {
              display: flex;
              justify-content: space-between;
              height: 100%;
              width: 100%;

              .container {
                height: 35px;
                width: 35px;

                @keyframes fadeIn {
                  from {
                    opacity: 0;
                    transform: scale(0.9);
                  }

                  to {
                    opacity: 1;
                    transform: scale(1);
                  }
                }

                .fadeInMenu {
                  animation: fadeIn 0.2s ease-in-out;
                  cursor: pointer;
                }
              }

              .calendarWeek {
                height: 90%;
                width: 100%;

                display: flex;
                flex-direction: column;
                border-radius: 15px;
                padding: 12px;
                margin-right: 10px;
                // margin: 12px 0;
                background-color: var(--modal-bg-color);



                .calendarWeekContent {
                  height: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 12px;

                  .calendarDayWrapper {
                    height: 95%;
                    width: calc((100vw - 400px) / 7 - 15px);

                    .calendarDayHeader {
                      display: flex;
                      justify-content: flex-start;

                      .calendarDayTitle {
                        color: var(--indication-color);
                        font-size: 14px;
                        margin-bottom: 5px;
                        font-weight: 500;
                        margin-right: 5px;
                      }
                    }


                    .calendarDay {
                      height: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      background-color: var(--card-bg-color);
                      border-radius: 5px;

                      &.selectedDay {
                        outline: 1px solid var(--info-color);
                      }

                      &.startDay {
                        outline: 1px solid var(--primary-color);
                      }

                      .calendarDayContent {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        height: 100%;
                        width: 100%;
                        padding: 12px;
                        border-radius: 5px;

                        &:hover {
                          outline: 1px solid var(--info-color);
                        }


                        .descriptionContainer {
                          display: flex;
                          justify-content: space-between;

                          .routineTitle {
                            color: var(--primary-color);
                            font-size: 12px;
                            font-weight: bold;
                            margin-bottom: 5px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 85%;
                          }

                          @keyframes fadeIn {
                            from {
                              opacity: 0;
                              transform: scale(0.9);
                            }

                            to {
                              opacity: 1;
                              transform: scale(1);
                            }
                          }

                          .fadeInMenu {
                            animation: fadeIn 0.2s ease-in-out;
                            height: 16px;
                            width: 16px;

                            .optionsBtn {
                              cursor: pointer;
                              height: 16px;
                              width: 16px;
                              color: var(--primary-color);
                              background-color: var(--main-bg-color);
                            }
                          }

                          .calendarDayDescription {
                            color: var(--primary-color);
                            font-size: 11px;
                            font-weight: 600;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          }
                        }

                        .calendarDayExercisesContainer {
                          display: flex;
                          flex-direction: column;
                          margin-top: 5px;
                          overflow-y: auto;

                          .calendarDayExerciseGroupContainer {
                            display: flex;
                            flex-direction: column;

                            .text {
                              color: var(--primary-color);
                              opacity: 0.5;
                              font-size: 11px;
                              font-weight: 500;
                              margin-bottom: 5px;
                            }

                            .calendarDayExerciseGroup {
                              margin-bottom: 5px;
                              background-color: var(--modal-bg-color);
                              padding: 5px;
                              border-radius: 5px;

                              .calendarDayExerciseSet {

                                .calendarDayDivider {
                                  border-bottom: 2px solid var(--card-bg-color);
                                  margin: 5px 0;

                                }

                                .calendarDayExerciseGroupName {
                                  color: var(--info-color);
                                  font-size: 12px;
                                  font-weight: 500;
                                }

                                .calendarDayExerciseGroupOptions {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  margin-top: 5px;

                                  .calendarDayExerciseGroupOptionText {
                                    color: var(--primary-color);
                                    font-size: 11px;
                                    font-weight: 600;
                                    margin-right: 5px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      .calendarDayOptions {
                        display: flex;
                        align-items: center;
                      }

                      .emptyDay {
                        width: 100%;
                        height: 100%;
                        background-color: var(--modal-bg-color);
                        display: flex;
                        justify-content: center;

                        &:hover {
                          background-color: rgba(0, 0, 0, 0.08) !important;
                        }

                        .addButton {
                          width: 100%;
                          border-radius: 10px;
                          background-color: transparent;
                          display: flex;
                          flex-direction: column;
                          // border: 1px solid var(--primary-color);

                          .addButtonText {
                            font-size: 12px;
                            font-weight: bold;
                            margin-top: 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .addWeekButton {
          margin-bottom: 20px;

          .addWeekButtonText {
            color: var(--primary-color);
            font-size: 16px;
            font-weight: bold;
            margin-left: 6px;
          }
        }
      }

      .rightContentFooter {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 12px 0;

        .action {
          border-radius: 10px;
          background-color: transparent;

          span {
            font-weight: bold;
            font-size: 12px;
          }
        }

        .submit {
          border: 1px solid var(--info-color);
          margin-right: 12px;

          .submitText {
            color: var(--info-color);
          }
        }

        .cancel {
          border: 1px solid var(--primary-color);

          .cancelText {
            color: var(--primary-color);
          }
        }
      }

    }
  }
}