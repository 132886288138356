@import '../../constants/styleConsts';

.editGroup {
  display: flex;
  height: calc(100% - 24px); // need padding from bottom + border radius (can't margin bottom)
  overflow: hidden;
  flex-direction: column;

  .searchHeading {
    display: flex;
    justify-content: start;
    align-items: center;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: bold;
    margin-top: 18px;
  }

  .search {
    flex-grow: 0;
    margin-top: 12px;
  }

  .list {
    flex-grow: 1;
    margin: 18px 0;

    .groupMultiSelectItem {
      display: grid;
      grid-template-columns: minmax(300px, 1fr) minmax(140px, 1fr) minmax(160px, 1fr);
      color: var(--primary-color);
      font-weight: bold;
      font-size: 14px;

      .groupNameWrapper {
        display: flex;
        align-items: center;
        justify-content: start;

        .groupName {
          margin-left: 6px;
          color: var(--info-color);
          text-decoration: underline;
        }
      }
    }
  }

  .submit {
    width: fit-content;
    align-self: end;

    @media (max-width: $small-screen-width) {
      width: 100%;
    }
  }
}
