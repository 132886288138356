.accountWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-bg-color);
  border-radius: 100px;
  height: 50px;
  padding: 0 6px 0 2px;

  .accountIcon {
    width: 46px;
    height: 46px;
  }

  .accountDetails {
    text-wrap: nowrap;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .accountName {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 14px;
      padding: 0 12px 0 8px;
    }

    .accountDescription {
      color: var(--primary-color);
      font-size: 12px;
    }
  }
}
