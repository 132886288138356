@import '../../constants/styleConsts';

.clientDataDialog {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .MuiTextField-root {
      flex: 1;
    }

    .rowColumn {
      width: 50%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }

    @media (max-width: $small-screen-width) {
      flex-direction: column;

      .rowColumn {
        width: 100%;
      }
    }
  }
}

.submit {
  min-width: 140px;

  .submitText {
    margin-left: 6px;
  }
}
