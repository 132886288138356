@import '../../constants/styleConsts';

.editTemplate {
  display: flex;
  height: calc(100% - 24px); // need padding from bottom + border radius (can't margin bottom)
  overflow: hidden;
  border-radius: 20px;

  .editTemplateContent {
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    padding-right: 18px;

    @media (max-width: $medium-screen-width) {
      padding-left: 12px;
      padding-right: 12px;
    }

    @media (max-width: $small-screen-width) {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .leftContent {
    background-color: var(--modal-bg-color);
    width: 340px;
    min-width: 340px;
    max-width: 340px;
    padding-top: 10px;

    @media (max-width: $medium-screen-width) {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }

    @media (max-width: $small-screen-width) {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }

    .title {
      color: var(--primary-color);
      font-size: 16px;
      margin-bottom: 18px;

      @media (max-width: $medium-screen-width) {
        font-size: 14px;
        text-align: center;
      }

      @media (max-width: $small-screen-width) {
        font-size: 12px;
        text-align: center;
      }
    }

    .editTemplateSearch {
      flex-grow: initial;
    }

    .exerciseList {
      overflow-y: auto;

      .exercises {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        min-height: calc(100vh - 200px);
        height: calc(100% - 24px);

        .exerciseItem {
          width: calc(50% - 0.5rem);
          /* 2 columns, adjust for gap */
          box-sizing: border-box;
          margin: 18px 0 12px;
          cursor: move;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: $medium-screen-width) {
            width: 100%;
            /* 1 column for small screens */
          }
        }
      }
    }
  }

  .rightContent {
    background-color: var(--modal-bg-color);
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 1px -4px 4px 0px #000000;
    padding-top: 18px;

    .rightContentHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .rightContentTitle {
        color: var(--info-color);
        font-size: 22px;
        font-weight: bold;

        @media (max-width: $medium-screen-width) {
          font-size: 18px;
        }
      }

      .templateName {
        justify-self: stretch;
        display: flex;
        flex-direction: column;
        width: 65%;

        @media (max-width: $medium-screen-width) {
          padding: 12px;
        }

        .templateNameHeader {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .templateNameTitle {
            color: var(--indication-color);
            font-size: 15px;
            font-weight: medium;
          }

          .templateNameEdit {
            cursor: pointer;
          }
        }

        .templateNameContent {
          font-size: 13px;
          color: var(--primary-color);

          .nameTextField {
            input {
              border: none;
              padding: 2px 0;
              font-size: 18px;
              font-weight: bold;
              color: var(--info-color);
            }

            &.editedName {
              margin-top: -1px;
              margin-left: -3px;

              input {
                border: 1px solid var(--indication-color);
                border-radius: 5px;
                padding: 2px;
                padding-left: 5px;
              }
            }

            fieldset {
              opacity: 0;
            }
          }

          .nameError {
            input {
              border: none;
              padding: 2px 0;
              font-size: 18px;
              font-weight: bold;
              color: var(--error-color);
            }

            &.editedName {
              margin-top: -1px;
              margin-left: -3px;

              input {
                border: 1px solid var(--error-color);
                border-radius: 5px;
                padding: 2px;
              }
            }

            fieldset {
              opacity: 0;
            }
          }
        }
      }

      .templateInfos {
        display: flex;
        justify-content: end;
        align-items: center;
      }
    }

    .templateContent {
      display: flex;
      background-color: var(--main-bg-color);
      border-radius: 15px;
      flex-direction: column;
      flex-grow: 1;
      // overflow-y: auto;
      // overflow-x: hidden;

      .templateDescription {
        justify-self: stretch;
        display: flex;
        flex-direction: column;
        border: 1px solid #515c72;
        border-radius: 15px;
        margin: 8px;
        padding: 18px;

        @media (max-width: $medium-screen-width) {
          padding: 12px;
        }

        .templateDescriptionHeader {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .templateDescriptionTitle {
            color: var(--indication-color);
            font-size: 13px;
            font-weight: bold;
          }

          .templateDescriptionEdit {
            cursor: pointer;
          }
        }

        .templateDescriptionContent {
          font-size: 13px;
          color: var(--primary-color);

          .descriptionTextField {
            input {
              border: none;
              padding: 2px 0;
              font-size: 15px;
            }

            &.editedDescription {
              margin-top: -1px;
              margin-left: -3px;

              input {
                border: 1px solid var(--indication-color);
                border-radius: 5px;
                padding: 2px;
              }
            }

            fieldset {
              opacity: 0;
            }
          }
        }
      }

      .templateGroups {
        flex-grow: 1;
      }

      .dropZone {
        height: 50px;
        opacity: 0;
        transition: opacity ease-in-out 0.2s;
        align-self: stretch;
        border: 1px dashed var(--info-color);
        border-radius: 10px;
        margin: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.dropZoneVisible {
          opacity: 1;
        }

        .dropZoneTitle {
          font-weight: bold;
          color: var(--info-color);
        }
      }
    }

    .rightContentFooter {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 12px 0;

      .action {
        border-radius: 10px;
        background-color: transparent;

        span {
          font-weight: bold;
          font-size: 12px;
        }
      }

      .submit {
        border: 1px solid var(--info-color);
        margin-right: 12px;

        .submitText {
          color: var(--info-color);
        }
      }

      .cancel {
        border: 1px solid var(--primary-color);

        .cancelText {
          color: var(--primary-color);
        }
      }
    }
  }
}