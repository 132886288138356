.exerciseGroup {
  margin: 6px 0.5rem;

  .dropZone {
    height: 10px;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    align-self: stretch;
    border: 1px dashed var(--indication-color);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;

    &.dropZoneVisible {
      height: 50px;
      opacity: 1;
    }

    .dropZoneTitleWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: var(--indication-color);


      .dropZoneTitle {
        font-weight: bold;
        color: var(--indication-color);
      }
    }
  }

  .headerWrapper {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: 0.5rem;

    .exerciseGroupTitle {
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-color);
    }

    .exerciseGroupDragHandle {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        cursor: move;
      }
    }
  }


  .exerciseGroupContent {
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--modal-bg-color);


    .groupSetsWrapper {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      padding: 0 0.5rem;
    }
  }
}