.velocityPie {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 15px;
  padding: 5px 10px 0px 10px;
  box-sizing: border-box;
}

.labelsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.label {
  text-align: center;
  color: white;
  font-size: 0.9em;
  font-weight: bold;
}

.barContainer {
  display: flex;
  width: 100%;
  height: 7px;
  border-radius: 15px;
  overflow: hidden;
}

.section {
  height: 100%;
}

.subtitle {
  margin-top: 2px;
  font-size: 0.8em;
  color: white;
  font-weight: 500;
  text-align: center;
}