.container {
  flex: 1;
  height: 100px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 2px solid;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  transition:
    border-color 0.2s ease;

  .title {
    font-size: 14px;
    color: var(--primary-color);
  }

  &.goodRate {
    border-color: green;
  }

  &.fastRate {
    border-color: yellow;
  }

  &.slowRate {
    border-color: red;
  }
}

.usersContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5px;
  justify-content: space-evenly;
  /* Evenly space user boxes */
  align-items: flex-start;
  height: 100%;
}

.userWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userBox {
  border-radius: 5px;
  width: 80%;
  height: 40px;
  opacity: 0.3;
  transform: scale(1);
  transition:
    opacity 0.2s ease,
    background-color 0.2s ease,
    transform 0.2s ease;

  &.active {
    opacity: 1;
    transform: scale(1.1);
  }

  &.goodRate {
    background-color: green;
  }

  &.fastRate {
    background-color: yellow;
  }

  &.slowRate {
    background-color: red;
  }
}

.userInitials {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  margin-top: 3px;
  /* Some spacing between box and initials */
  text-align: center;
  opacity: 0.3;
  transition: opacity 0.2s ease;

  &.active {
    opacity: 1;
  }
}