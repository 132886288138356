.container {
  .header {

    .tabsContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .tabs {
        display: flex;
        gap: 10px;

        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          border-radius: 8px;
          border: 2px solid var(--indication-color);
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          color: var(--indication-color);
          background-color: var(--secondary-bg-color);
          transition: background-color 0.3s;

          &:hover {
            border-color: var(--info-color);
          }

          &.selected {
            border-color: var(--info-color);
            color: var(--info-color);
          }

          &+.tab {
            margin-left: 8px;
          }
        }
      }

      .button {
        background-color: var(--info-color);
        height: 45px;
        border-radius: 12px;
        margin-left: 12px;

        svg {
          path {
            fill: var(--primary-color);
          }
        }

        .buttonText {
          font-weight: bold;
          margin-left: 10px;
          margin-right: 4px;
          text-transform: none;
          text-wrap: nowrap;
        }
      }
    }

    .subtitle {
      font-size: 14px;
      font-weight: 600;
      color: var(--indication-color);
    }
  }

  .configurationView {
    width: 100%;
    height: 100%;
    background-color: 'green';
    border: 1px solid var(--border-color);

    .list {
      flex-grow: 1;
      margin: 18px 0;

      .gymMultiSelectItem {
        display: grid;
        grid-template-columns: minmax(300px, 1fr) minmax(140px, 1fr) minmax(160px, 1fr);
        color: var(--primary-color);
        font-weight: bold;
        font-size: 12px;

        .configNameWrapper {
          display: flex;
          align-items: center;
          justify-content: start;

          .configName {
            color: var(--primary-color);
          }
        }
      }
    }
  }

}