@import '../../constants/styleConsts';

.programDataDialog {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (max-width: $small-screen-width) {
      flex-direction: column;
      margin-bottom: 8px;
    }

    > * {
      flex: 1;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 1px solid var(--info-color) !important;
  border-radius: 12px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.submitText {
  margin: 0 8px;
  color: var(--info-color);
  font-size: 12px;
  font-weight: bold;
}

.radioInput {
  color: var(--primary-color);
}
