.assignProgramDialog {
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-x: hidden;

  .assignProgramSearch {
    flex-grow: 0;
    width: 100%;
    max-width: none;
    margin-bottom: 12px;
  }

  .programItems {
    width: 100%;
    overflow-x: auto;

    .programItemWrapper {
      cursor: pointer;
      border-radius: 4px;
      width: fit-content;

      &.selectedProgramItem {
        border: 1px solid var(--info-color);
      }
    }
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 1px solid var(--info-color) !important;
  border-radius: 10px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  .submitText {
    margin: 0 8px;
    color: var(--info-color);
    font-size: 12px;
    font-weight: bold;
  }
}

.calendarDialog {
  display: flex;
  align-items: center;
  flex-direction: column;

  .customCalendar {
    background-color: var(--calendar-bg-color);
    border-radius: 15px;
    height: 65vh;
    width: 90%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    :global(.react-datepicker__navigation) {
      top: 5%;
    }

    :global(.react-datepicker__navigation--next) {
      right: 5%;
    }

    :global(.react-datepicker__navigation--previous) {
      left: 5%;
    }

    :global(.react-datepicker__navigation-icon:before) {
      border-color: var(--info-color);
      height: 13px;
      width: 13px;
      border-width: 3px 3px 0 0;
    }

    :global(.react-datepicker__header) {
      background-color: var(--calendar-bg-color);
      height: 25%;
      margin-bottom: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

    }

    :global(.react-datepicker__day--selected) {
      background-color: var(--info-color);
      color: var(--primary-color);
      border-radius: 50%;
    }

    :global(.react-datepicker__month-container) {
      width: 90%;
      height: 100%;
    }

    :global(.react-datepicker__current-month) {
      color: var(--primary-color);
      font-size: 22px;
      // padding-top: 2%;
    }

    :global(.react-datepicker__day-names) {
      display: flex;
      justify-content: space-between;
    }

    :global(.react-datepicker__day-name) {
      color: var(--indication-color);
      font-size: 20px;
      font-weight: 600;
    }

    :global(.react-datepicker__month) {
      height: 60%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }

    :global(.react-datepicker__week) {
      display: flex;
      justify-content: space-between;
    }

    :global(.react-datepicker__day) {
      color: var(--primary-color);
      font-size: 20px;
      font-weight: 400;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :global(.react-datepicker__day:hover) {
      color: var(--primary-color);
      background-color: var(--info-color);
      border-radius: 50%;
      font-size: 18px;
      font-weight: 400;
    }

    :global(.react-datepicker__day--outside-month) {
      color: #666;
    }
  }
}