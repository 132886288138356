@import '../../constants/styleConsts';
$group-set-grid-columns: minmax(60px, 0.5fr) // "SET #"
  minmax(80px, 1fr) // Weight
  minmax(80px, 1fr) // Reps
  minmax(80px, 1fr) // Rest
  minmax(80px, 1fr) // Effort
  minmax(60px, 0.5fr); // Delete icon

$automatic-group-set-grid-columns: minmax(60px, 0.5fr) // "SET #"
  minmax(50px, 1fr) // Weight
  minmax(50px, 1fr) // Reps
  minmax(50px, 1fr) // Rest
  minmax(70px, 1fr) // Effort
  minmax(70px, 1fr) // Concentric range
  minmax(70px, 1fr) // Eccentric range
  minmax(60px, 0.5fr); // Delete icon


.groupSet {

  width: 100%;
  padding: 12px 12px 12px 6px;
  background-color: var(--modal-bg-color);

  @media (max-width: $medium-screen-width) {
    padding: 12px 8px 12px 4px;
  }

  @media (max-width: $small-screen-width) {
    padding: 12px 6px 12px 2px;
  }

  .groupSetContent {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;



    &.circuit {
      // box-shadow: 0 -8px 10px -10px rgba(0, 0, 0, 1);
    }

    .dragGroupSet {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 6px;
      cursor: move;
    }

    .exerciseImage {
      align-self: start;
      min-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }


    .groupDetails {
      flex-grow: 1;
      overflow-x: auto;
      padding-left: 16px;

      @media (max-width: $medium-screen-width) {
        padding-left: 10px;
      }

      @media (max-width: $small-screen-width) {
        padding-left: 4px;
      }

      .groupDetailsHeader {
        cursor: pointer;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.withContent {
          margin-top: 5px;
        }

        .groupDetailsTitle {
          display: flex;
          justify-content: start;
          align-items: center;

          .exerciseNumber {
            font-size: 14;
            font-weight: 600;
            color: var(--info-color);
            margin-right: 6px;
          }

          .groupDetailsIcon {
            background-color: var(--main-bg-color);
            border-radius: 50%;
            width: 25px;
            height: 25px;
            margin-right: 10px;

            svg {
              margin-left: 1px;
            }
          }

          .exerciseName {
            color: var(--primary-color);
            font-weight: bold;
            text-wrap: nowrap;
          }
        }
      }

      .sets {
        display: flex;
        flex-direction: column;
        width: 100%;

        .automaticSetProperties {
          display: grid;
          grid-template-columns: $automatic-group-set-grid-columns;
          min-width: 600px; // Ensure this exceeds the view width
        }

        .setProperty {
          text-align: center;
          font-size: 8px;
          font-weight: bold;
          color: var(--indication-color);
          white-space: nowrap;
        }
      }

      .addSet {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .addSetButton {
          svg {
            margin-right: 6px;
          }

          background-color: transparent;
          font-weight: bold;
          color: var(--info-color);
        }
      }
    }

  }

  .dropZone {
    height: 10px;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    align-self: stretch;
    border: 1px dashed var(--indication-color);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;

    &.dropZoneVisible {
      height: 50px;
      opacity: 1;
    }

    .dropZoneTitleWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: var(--indication-color);


      .dropZoneTitle {
        font-weight: bold;
        color: var(--indication-color);
      }
    }
  }
}