.fileUploadContainer {
  margin-bottom: 16px;
  position: relative;

  .description {
    color: var(--indication-color);
    font-size: 12px;
  }
}

.uploadBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  border: 2px solid var(--indication-color);
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: #aaa;
  }

  .uploadLabel {
    color: var(--indication-color);
  }

  .media {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }

  &.uploadBoxError {
    border-color: var(--error-color);
  }
}

.uploadLabelContainer {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  color: var(--indication-color);

  .uploadIcon {
    color: var(--indication-color);
    font-size: 48px;
    margin-bottom: 8px;
  }
}

.uploadButton {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.clearButton {
  position: absolute !important;
  top: -10px;
  right: -10px;
  background-color: transparent !important;
  padding: 0 !important;
  min-width: auto !important;

  &:hover {
    background-color: transparent !important;
  }

  svg {
    border-radius: 100%;
    background-color: var(--info-color);

    path {
      fill: var(--modal-bg-color);
    }
  }
}

.editButton {
  position: absolute !important;
  bottom: -15px !important;
  right: -12px !important;
  background-color: var(--main-bg-color) !important;
  padding: 0 8px !important;
  min-width: auto !important;
  height: 30px;
  text-transform: none !important;
  font-size: 14px;
  font-weight: bold !important;
  border-radius: 15px !important;
  color: var(--info-color) !important;

  &:hover {
    background-color: rgba(37, 45, 60, 0.95) !important;
  }

  svg {
    margin-right: 10px;
    border-radius: 100%;
    background-color: var(--info-color);

    path {
      fill: var(--modal-bg-color);
    }
  }

  .changeMedia {
    width: 20px;
    height: 20px;
    color: var(--indication-color);

    &:hover {
      opacity: 0.8;
    }
  }
}

.error {
  border-color: red !important;
}
