.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--modal-bg-color);
  border-radius: 20px;
}

.userRow {
  padding: 20px;
  border-radius: 20px;
  border-bottom: 1px solid var(--main-bg-color);
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.userName {
  font-weight: bold;
  color: var(--info-color);
  font-size: 18px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

/* Add a separate class for the dot */
.separatorDot {
  font-size: 35px;
  line-height: 1;
  color: var(--info-color);
  display: inline-block;
}

.rackNumber {
  color: var(--info-color);
  font-size: 20px;
  font-weight: bold;
}

.attempts {
  color: var(--indication-color);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  &.fast {
    color: yellow;
  }

  &.slow {
    color: red;
  }

  margin-left: 5px;
}

.warningIcon {
  vertical-align: middle;
}

.statusLabel {
  position: absolute;
  right: 20px;
  padding: 2px 8px;
  border: 2px solid;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: bold;
}

.orangeBorder {
  border-color: var(--info-color);
  color: var(--info-color);
}

.whiteBorder {
  border-color: white;
  color: white;
}

.greenBorder {
  border-color: green;
  color: var(--primary-color);
}

.yellowBorder {
  border-color: yellow;
  color: var(--primary-color);
}

.redBorder {
  border-color: red;
  color: var(--primary-color);
}

.mainContent {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 45px;
}

.leftSide {
  width: 120px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
}

.exerciseName {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.loggedWeight {
  font-size: 14px;
  color: white;
}

.loggedWeightValue {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.rightSide {
  flex: 1;
  overflow-x: auto;
}

.repList {
  display: flex;
  gap: 10px;
}