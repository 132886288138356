@import '../../constants/styleConsts';

.exerciseThumbnailContainer {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: $small-screen-width) {
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
  }

  .image {
    flex-grow: 1;
    width: 100%;
    height: 70%;
    max-height: 70%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .exerciseNameWrapper {
    width: 100%;
    background-color: var(--main-bg-color);
    height: 40px;
    line-height: 14px;
    padding: 3px 6px;

    .exerciseName {
      color: var(--primary-color);
      font-size: 12px;
      text-align: left;

      @media (max-width: $small-screen-width) {
        font-size: 10px;
      }
    }
  }
}
