.clientsContentTabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    border: 2px solid var(--indication-color);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: var(--indication-color);
    background-color: var(--secondary-bg-color);
    transition: background-color 0.3s;

    &:hover {
      border-color: var(--info-color);
    }

    &.selected {
      border-color: var(--info-color);
      color: var(--info-color);
    }

    // &:first-child {
    //   border-top-left-radius: 8px;
    //   border-bottom-left-radius: 8px;
    // }

    // &:last-child {
    //   border-top-right-radius: 8px;
    //   border-bottom-right-radius: 8px;
    // }

    &+.tab {
      margin-left: 8px;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    color: var(--indication-color);
  }

  .velocityPiesContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
    background-color: var(--modal-bg-color);
    border-radius: 15px;

    .velocityPieContainer {
      width: 100%;
      display: flex;
      justify-content: center;

      &+.velocityPieContainer {
        margin-left: 30px;
      }

      .emptyStat {
        font-size: 14px;
        color: var(--primary-color);
        text-align: center;
      }
    }
  }
}