.userRow {
  display: grid;
  padding: 2px 18px;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid var(--main-bg-color);
  width: fit-content;
  min-width: 100%;

  &.selected {
    background-color: var(--secondary-bg-color);
  }

  &:last-child {
    border-bottom: none;
  }

  .userColumn {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 14px;

    &.userName {
      justify-content: start;
      color: var(--info-color);
      text-decoration: underline;

      .userIcon {
        margin-right: 8px;
      }
    }

    .userColumnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 36px;
      border: 1px solid var(--primary-color);
      border-radius: 8px;
      text-wrap: wrap;
    }

    &.options {
      justify-content: center;

      .optionsBtn {
        cursor: pointer;
      }
    }
  }
}

.clientListGrid {
  grid-template-columns: minmax(250px, 1fr) minmax(140px, 1fr) minmax(220px, 1fr) minmax(160px, 1fr) minmax(160px, 1fr) minmax(60px, 1fr) minmax(80px, 1fr);
}

.athleteListGrid {
  grid-template-columns: minmax(250px, 1fr) minmax(220px, 1fr) minmax(160px, 1fr) minmax(160px, 1fr) minmax(60px, 1fr) minmax(80px, 1fr);
}

.workoutsListGrid {
  grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(80px, 1fr);
}