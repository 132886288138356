@import '../constants/styleConsts';

.navigationBar {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 250px;
  padding: 30px 18px;
  box-sizing: border-box;
  text-align: center;

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    .logoImage {
      width: 100%;
      height: auto;
    }
  }

  .navigationBarContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
  }

  .userInfo {
    text-align: center;

    .userName {
      margin-bottom: 1rem;
    }
  }

  .menuButton {
    display: none;
  }

  .userInfoMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: $small-screen-width) and (max-width: 1200px) {
    padding: 18px 8px;
    min-width: 220px;
    width: 220px;
  }

  @media (max-width: $small-screen-width) {
    flex-direction: row;
    height: auto;
    width: 100%;
    align-items: center;
    padding: 18px;
    justify-content: space-between;

    .logo {
      margin-bottom: 0;
      margin-right: 1rem;
      width: 30%;
      min-width: 120px;
    }

    .navigationBarContent,
    .userInfo {
      display: none;
    }

    .menuButton {
      display: block;
    }

    .navigationLogoutWrapper {
      display: none;
    }
  }
}

.responsiveMenu {
  width: 100%;
  max-width: 250px;

  :global(.MuiPaper-root) {
    background: linear-gradient(to bottom, #10161f, #373750) !important;
  }
}

.navItem {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 5px;

  .navLabel {
    font-weight: bold;
    margin-left: 10px;
    font-size: 1.25rem;
  }

  &.selectedNav {
    background: linear-gradient(to right, #3a3f49, transparent);

    .navLabel {
      color: var(--info-color);
    }
  }

  &.unselectedNav {
    background: transparent;
    color: var(--primary-color);
  }
}

