@import '../constants/styleConsts';

.dashboard {
  display: flex;
  height: 100vh;
  overflow: hidden;

  .mainContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: $small-screen-width) {
  .dashboard {
    flex-direction: column;
  }
}
