.groupRow {
  display: grid;
  padding: 2px 18px;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid var(--main-bg-color);
  width: fit-content;
  min-width: 100%;

  &.selected {
    background-color: var(--secondary-bg-color);
  }

  &:last-child {
    border-bottom: none;
  }

  .groupColumn {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 14px;

    &.groupName {
      justify-content: start;
      color: var(--info-color);
    }

    &.options {
      justify-content: center;

      .optionsBtn {
        cursor: pointer;
      }
    }
  }
}

.listGrid {
  grid-template-columns: minmax(220px, 1fr) minmax(160px, 1fr) minmax(160px, 1fr) minmax(80px, 1fr);
}
