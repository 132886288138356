.templateRow {
  display: grid;
  padding: 2px 18px;
  height: 70px;
  align-items: center;
  border-bottom: 1px solid var(--main-bg-color);
  width: fit-content;
  min-width: 100%;

  &.selected {
    background-color: var(--secondary-bg-color);
  }

  &:last-child {
    border-bottom: none;
  }

  .templateColumn {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 14px;

    &.nameAndDescription {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      text-align: left;

      .templateName {
        color: var(--info-color);
        font-size: 18px;
        font-weight: bold;
      }
    }

    &.options {
      justify-content: center;

      .optionsBtn {
        cursor: pointer;
      }
    }
  }
}

.listGrid {
  grid-template-columns: minmax(250px, 1fr) minmax(160px, 1fr) minmax(555px, 1fr) minmax(80px, 1fr);
}