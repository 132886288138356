.container {
  background: linear-gradient(to bottom, #10161f, #373750);
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.leftSide {
  flex: 1;
  background: url('https://motivision-images.s3.amazonaws.com/motivition-dashboard-logo.png')
    no-repeat center center;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }
}

.rightSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    width: 100%;
    box-shadow: none;
    padding: 1rem;
  }

  .rightSideContentWrapper {
    width: 100%;
    max-width: 600px;
  }
}

.sendVerificationCode {
  min-height: 35px;
}

.loginTitle {
  color: var(--info-color);
  text-align: left;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
}

.loginDescription,
.emailErrorDescription {
  color: var(--primary-color);

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }
}
