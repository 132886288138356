.confirmationDialog {
  :global(.MuiPaper-root) {
    background-color: var(--modal-bg-color);
    border-radius: 20px;
    padding: 18px;
    max-width: 320px;
  }

  .dialogHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .closeDialog {
      cursor: pointer;
    }
  }

  .dialogContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;

    svg {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
    }

    .dialogText {
      font-size: 22px;
      color: var(--primary-color);
    }
  }

  .dialogActions {
    justify-content: center;
    padding: 10px;

    button {
      flex-grow: 1;
      border-radius: 10px;
    }

    .cancel {
      background-color: transparent;
      border: 1px var(--info-color) solid;

      &:hover {
        background-color: var(--info-color);
      }
    }
  }
}
