.userWorkoutsRow {
  display: grid;
  padding: 2px 18px;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid var(--main-bg-color);
  width: fit-content;
  min-width: 100%;

  &.selected {
    background-color: var(--secondary-bg-color);
  }

  &:last-child {
    border-bottom: none;
  }

  .userColumn {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 14px;

    &.userName {
      justify-content: start;
      color: var(--info-color);
      text-decoration: underline;

      .userIcon {
        margin-right: 8px;
      }
    }

    .userColumnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 36px;
      border: 1px solid var(--primary-color);
      border-radius: 8px;
      text-wrap: wrap;
    }

    &.options {
      justify-content: center;

      .optionsBtn {
        cursor: pointer;
      }
    }
  }
}

.workoutsListGrid {
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(655px, 1fr) minmax(80px, 1fr);

  .complianceRateContainer {
    display: flex;
    justify-content: center;
    position: relative;

    .rateTextContainer {
      position: relative;
      display: flex;
      align-items: center;

      &+.rateTextContainer {
        margin-left: 8px;
      }
    }

    .rateText {
      font-size: 14px;
      color: var(--primary-color);
      font-weight: bold;
      cursor: pointer;
    }

    .tooltip {
      width: 150px;
      position: absolute;
      bottom: 120%;
      /* Adjust for proper positioning above the text */
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--main-bg-color);
      color: var(--primary-color);
      font-weight: bold;
      font-size: 12px;
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid var(--primary-color);
      white-space: wrap;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease, visibility 0.2s ease;
    }

    .rateTextContainer:hover .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  .velocityPiesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .velocityPieContainer {
      width: 100%;
      display: flex;
      justify-content: center;

      &+.velocityPieContainer {
        margin-left: 16px;
      }

      .emptyStat {
        font-size: 14px;
        color: var(--primary-color);
        text-align: center;
      }
    }

  }
}