@import '../../constants/styleConsts';

.exerciseDataDialog {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;

  @media (max-width: $small-screen-width) {
    flex-direction: column;
  }
}

.leftContent,
.rightContent {
  display: flex;
  flex-direction: column;
  width: 47%;

  @media (max-width: $small-screen-width) {
    width: 100%;
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 1px solid var(--info-color) !important;
  border-radius: 12px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.submitText {
  margin: 0 8px;
  color: var(--info-color);
  font-size: 12px;
  font-weight: bold;
}

.radioInput {
  color: var(--primary-color);
}
