@import '../../constants/styleConsts';

.iconWithText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;

  svg {
    width: 22px;
    path {
      fill: var(--indication-color);
    }
  }

  .text {
    color: var(--primary-color);
    font-size: 14px;
    margin: 0 4px;
    text-wrap: nowrap;

    @media (max-width: $medium-screen-width) {
      font-size: 12px;
    }

    @media (max-width: $small-screen-width) {
      font-size: 10px;
    }
  }
}
