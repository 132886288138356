.addWorkoutsDialog {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  height: 250px;

  .search {
    max-width: none;
    margin-bottom: 12px;
    flex-grow: 0;
  }

  .templateMultiSelectItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary-color);

    .templateName {
      color: var(--info-color);
      font-weight: bold;
    }
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 1px solid var(--info-color) !important;
  border-radius: 12px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  .submitText {
    margin: 0 8px;
    color: var(--info-color);
    font-size: 12px;
    font-weight: bold;
  }
}
