@import '../../constants/styleConsts';

.baseDialog {
  :global(.MuiPaper-root) {
    width: 70%;
    background-color: var(--modal-bg-color);
    border-radius: 20px;
    max-height: 85%;
    max-width: 100%;

    @media (max-width: $small-screen-width) {
      width: 100%;
    }
  }

  .dialogHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: var(--primary-color);
      font-weight: bold;
    }

    .closeDialog {
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .dialogActions {
    button {
      margin: 0 16px 16px;
    }

    @media (max-width: $small-screen-width) {
      justify-content: center;

      button {
        flex-grow: 1;
      }
    }
  }
}