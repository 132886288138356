$small-screen-width: 600px;
$medium-screen-width: 1000px;

:root {
  --primary-color: #ffffff;
  --info-color: #fa5723;
  --indication-color: #7b8aa8;
  --main-dashboard-bg-color: #11161f;
  --main-bg-color: #252d3c;
  --secondary-bg-color: #131821;
  --modal-bg-color: #191f2b;
  --error-color: #9b0038;
  --card-bg-color: #262D3C;
  --calendar-bg-color: #282D35;
  --empty-slot-color: #7B8AA866;
}