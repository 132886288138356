.inputFieldItem {
  width: 100%;
  margin-top: 4px;

  :global(.MuiFormLabel-root) {
    color: #797d82;
    font-size: 14px;
    margin-top: 2px;
  }

  .fieldTitleWrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 8px;

    .fieldTitle {
      color: var(--primary-color);
      font-size: 16px;
      font-weight: bold;
      margin-left: 6px;
    }
  }

  .inputWrapper {
    position: relative;

    :global(.MuiTextField-root),
    :global(.MuiFormControl-root) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .inputField {
      :global(.MuiInputBase-root) {
        color: var(--primary-color);

        fieldset {
          border-color: var(--indication-color);
        }
      }

      &.inputFieldError {
        fieldset {
          border-color: var(--error-color);
        }
      }

      :global(.MuiSelect-icon) {
        color: var(--info-color);
      }

      &.inputFieldDropDown {
        :global(.MuiInputBase-root) {
          height: 30px;
        }
      }

      &.multiSelectInputField {
        color: var(--primary-color);
        border: 1px solid var(--indication-color);
        border-radius: 14px;

        &.inputFieldError {
          border-color: var(--error-color);
        }

        fieldset {
          border: none;
        }
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }

    .inputFieldPlaceholder {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: #797d83;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 14px;
      font-size: 12px;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;

      &.inputFieldPlaceholderHidden {
        visibility: hidden;
      }

      &.dropDownInputPlaceholder {
        visibility: visible;
      }
    }
  }
}